const npm = require('../../package.json');

export const environment = {
    local: false,
    production: false,
    dev: false,
    qa: false,
    uat: false,
    app: {
        name: 'Cubic Telecom Automotive B2C Portal',
        version: npm.version,
        activationLink: '__env.activationLink__',
        resetPasswordLink: '__env.resetPasswordLink__',
        myAccountLink: '__env.myAccountLink__',
        faqLink: '__env.faqLink__',
        purchaseLink: '__env.purchaseLink__',
        reUploadLink: '__env.reUploadLink__',
        resellerCode: 'seat',
        theme: 'seat',
        themeColour: '#87c80a',
        assetVersionNumber: '2',
        analyticsToolsIDs: {
            gaTrackingId: '__env.gaTrackingId__',
            hotjarTrackingId: '__env.hotjarTrackingId__',
            optimizePluginId: '__env.optimizePluginId__'
        },
        dataDogIds: {
            applicationId: 'aa31517d-cdb4-4c76-a439-dc130afb8466',
            clientToken: 'pub6704084b25120891661332937b528aea',
            site: 'datadoghq.eu',
            service: 'pv1-webshop',
            version: '1.0.1',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input'
          },
        features: {
            showVehicleImage: true,
            showRegCompleteVideo: false,
            showSkodaLink: false,
            showInCarTopup: true,
            regCompleteVideoUrl: '',
            showQRCodeScanner: false,
            ssoSettings: {
                appName: '',
                enabled: false,
                returnUrl: '__env.returnUrl__',
                scopes: []
              }
        },
        browserRequirements: [{
            browser: 'chrome',
            minVersion: 65
        },
        {
            browser: 'safari',
            minVersion: 10
        },
        {
            browser: 'firefox',
            minVersion: 57
        },
        {
            browser: 'ie',
            minVersion: 11
        }],
        minLogLevel: 'info'
    },
    system: {
        websiteName: '__env.websiteName__',
        hostname: '__env.hostname__'
    },
    azure: {
        appInsightsInstKey: '__env.appInsightsInstKey__'
    },
    api: {
        endpoint: '__env.endpoint__'
    }
};
